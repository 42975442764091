import NavigationScroll from "container/layout/NavigationScroll";
import { lazy } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
} from "react-router-dom";
import Loader from "ui-component/Loader";
import { AuthProvider } from "./auth.provider";
import GuestRoute from "./GuestRoute";
import ProtectedUserRoute from "./ProtectedRoute";
import Remainder from "pages/remainder";
import Vehicle_List from "pages/vehicle/vehiclecustomerlist";
import VehicleViewCustomer from "pages/vehicle/vehiclecustomerview";
import ReminderCreate from "pages/remainder/create";
import Testimonial from "pages/setting/Testimonial";
import NotFound from "pages/pagenotfound/Notfound";
import TestimonialList from "pages/setting/TestimonialList";
import PolicyBalance from "pages/dashboard/PolicyBalance";
import DailyLedger from "pages/dashboard/DailyLedger";
import PaymentToCarrier from "pages/dashboard/PaymentToCarrier";
import PolicyCollection from "pages/dashboard/PolicyCollection";
import DdcReports from "pages/dashboard/Ddc";
import Landing from "pages/setting/Landing";
import PolicyCancellationForm from "pages/policy/cancel/PolicyCancelForm";
import { Roles } from "helpers/enum.helpers";
import EmailConfig from "pages/setting/EmailConfig";
import SmsConfig from "pages/setting/SmsConfig";
import PaymentConfig from "pages/setting/location/PaymentConfig";
import Location from "pages/setting/location/Location";
import LocationCreate from "pages/setting/location";
import LocationView from "pages/setting/location/view";
import SubscriptionPlans from "pages/Subscriptions";
import Paymentsuccess from "pages/paymentsucess/paymentsuccess";
import Paymentfailed from "pages/paymentfailed/paymentfailed"
import NotificationSection from "pages/notification/Notification";
import CustomerPolicyLocation from "pages/Customer_policy/customer_policy";
import CustomerPolicy from "pages/Customer_policy";
import TransactionHistory from "pages/TransactionHistory";
import SuspensionPolicy from "pages/dashboard/SuspensionPolicy";
import PolicyCountBasedOnInsuranceCompany from "pages/reports/PolicyCoutnBasedOnInsuranceCompany";
import CustomerListReport from "pages/reports/CustomerListReport";
import FeesReport from "pages/reports/FeesReport";
import ReinstatementReport from "pages/reports/ReinstatementReport";
import ReinstatementDueReport from "pages/reports/ReinstatementDueReport";
import PolicyRenewalReport from "pages/reports/PolicyRenewalReport";
/* guest routes */
const PageNotFound = lazy(() => import("pages/pagenotfound"));

const ComingSoon = lazy(() => import("forms/hoc/coming-soon"));

const Login = lazy(() => import("pages/authentication/Login"));
const SetPassword = lazy(() => import("pages/authentication/SetPassword"));
const ForgotPassword = lazy(
  () => import("pages/authentication/ForgotPassword")
);
const OtpPage = lazy(() => import("pages/authentication/OtpPage"));
const LandingPage = lazy(() => import("pages/landingPage/Index"));
const MakePayments = lazy(()=> import("pages/Make-payments/Index"));
/* layout component */
const Layout = lazy(() => import("../layout/layout/MainLayout"));

// Pages
const CustomerDashboard = lazy(() => import("pages/dashboard/CustomerDashboard"));
const ClientList = lazy(() => import("pages/clients/list"));
const CreateClient = lazy(() => import("pages/clients/create"));
const ClientView = lazy(() => import("pages/clients/view"));
const CreatePolicy = lazy(() => import("pages/policy/create"));
const ViewPolicy = lazy(() => import("pages/policy/view"));
const PolicyList = lazy(() => import("pages/policy/list"));
const CreateQuote = lazy(() => import("pages/quote/create"));

const Dashboard = lazy(() => import("pages/dashboard"));
const Employees = lazy(() => import("pages/employee/list"));
const EmployeeCreate = lazy(() => import("pages/employee/create"));
const QuotationsList = lazy(() => import("pages/quote/list"));
const QuotationView = lazy(() => import("pages/quote/view"));
const Profile = lazy(() => import("pages/profile"));
const ResetPassword = lazy(() => import("pages/resetPassword"));
const Subscription = lazy(() => import("pages/subscription/Subscription"));
const KnowledgeBase = lazy(() => import("pages/knowledgeBase/KnowledgeBase"));
const Success = lazy(() => import("pages/policy/invoice/payment/success"));
const Decline = lazy(() => import("pages/policy/invoice/payment/decline"));
const MakePaymentSuccess = lazy(()=> import("pages/Make-payments/MakePaymentSuccess"))
const MakePaymentCancel = lazy(()=> import ("pages/Make-payments/MakePaymentCancel"))
const Reconciliation = lazy(() => import("pages/reconciliation"));
const Reports = lazy(() => import("pages/reports"));
const Chequelist = lazy(() => import("pages/cheque"));
const ChequeEdit = lazy(() => import("pages/cheque/edit"));
const ChequeView = lazy(() => import("pages/cheque/view"));
const DisplayCard = lazy(() => import("settings_card/Displaycard"));
const Company = lazy(() => import("pages/setting/company"));
const MasterType = lazy(() => import("pages/setting/masterType"));
const Industry = lazy(() => import("pages/setting/Industry"));
const DocuSign = lazy(() => import("../pages/docusign"));
const DocuSignVerify = lazy(() => import("../pages/docusign/verification"));
const EndorsementNotification = lazy(() => import("../pages/endorsement"));

const appLoader = () => {
  return <Loader />;
};

const AuthLayout = () => (
  <AuthProvider>
    <NavigationScroll>
    <Outlet />
    </NavigationScroll>
  </AuthProvider>
);

const RoutePath = createBrowserRouter(
  [
    {
      path:'/',
      element:<AuthLayout />,
      children:[
      {
        path: "/",
        element: <GuestRoute />,
        loader: appLoader,
        children: [
          {
            path: "",
            element: <Navigate to="quote" replace />,
          },
          {
            path: "/login",
            element: <Login />,
          },
          {
            path:"/make-payment",
            element: <MakePayments />,
          },
          {
            path:"/makePayment-success",
            element: <MakePaymentSuccess />
          },
          {
            path:"/makePayment-cancel",
            element: <MakePaymentCancel/>
          },
          {
            path: "/domain-not-found",
            element: <NotFound />,
          },
          {
            path: "/setpassword",
            element: <SetPassword />,
          },
          {
            path: "/forgotpassword",
            element: <ForgotPassword />,
          },
          {
            path: "/generateotp",
            element: <OtpPage />,
          },
          {
            path: "/quote",
            element: <LandingPage/>,
          },
        ],
      },
      {
        path: "/",
        element: <ProtectedUserRoute roles={[Roles.Customer, Roles.TenantAdmin, Roles.TenantEmployee]} />,
        children: [
          {
            path: "",
            element: <Layout />,
            children: [
              {
                path: "/quotes",
                element: <QuotationsList />,
              },
              {
                path: "/customer-policy",
                element: <CustomerPolicy />,
              },
              // {
              //   path: "/customer-policy/add",
              //   element: <CustomerPolicyLocation open={false} handleClose={undefined} handleFormSubmit={undefined} />,
              // },
              {
                path:"transaction-history",
                element:<TransactionHistory/>
              },
              {
                path: "/quote/create",
                element: <CreateQuote />,
              },
              {
                path: "/quote/edit/:id",
                element: <CreateQuote />,
              },
              {
                path: "/quote/view/:id",
                element: <QuotationView />,
              },
              {
                path: "/policy",
                element: <PolicyList />,
              },
              {
                path: "/customer/Vehicle",
                element: <Vehicle_List />,
              },
              {
                path: "/customer/Vehicle/view/:id",
                element: <VehicleViewCustomer />,
              },
              {
                path: "/customers/policy/view/:id",
                element: <ViewPolicy />,
              },
              {
                path: "/resetPassword",
                element: <ResetPassword />,
              },
              {
                path: "/subscription",
                element: <Subscription />,
              },
              {
                path: "/subscriptionplans",
                element: <SubscriptionPlans />,
              },
              {
                path:"/knowledgeBase",
                element: <KnowledgeBase />,
              },
              {
                path: "/coming_soon",
                element: <ComingSoon />,
              },
              {
                path: "/notification",
                element: <NotificationSection/>,
              },
              {
                path: `/customer/policy/docusign-verify`,
                element: <DocuSignVerify />,
              },
            ],
          },
        ],
      },
      {
        path: "/",
        element: <ProtectedUserRoute roles={[Roles.TenantAdmin]} />,
        children: [
          {
            path: "",
            element: <Layout />,
            children: [
              {
                path:"/settings",
                element:<DisplayCard/>
              },
              {
                path:"/settings/insurance",
                element:<Company/>
              },
              {
                path:"/settings/masters",
                element:<MasterType/>
              },
              {
                path:"/settings/industry",
                element:<Industry/>
              },
              {
                path:"/settings/landing",
                element:<Landing/>
              },
              {
                path:"testimonial",
                element:<Testimonial/>
              },
              {
                path:"reports/policyBalance",
                element:<PolicyBalance/>
              },
              {
                path:"reports/policy-count",
                element:<PolicyCountBasedOnInsuranceCompany/>
              },
              {
                path:"reports/customer-list",
                element:<CustomerListReport/>
              },
              {
                path:"reports/fees-report",
                element:<FeesReport/>
              },
              {
                path:"reports/reinstatement-report",
                element:<ReinstatementReport/>
              },
              {
                path:"reports/reinstatement-due-report",
                element:<ReinstatementDueReport/>
              },
              {
                path:"reports/policy-renewal-report",
                element:<PolicyRenewalReport/>
              },
              {
                path:"reports/paymentToCarrier",
                element:<PaymentToCarrier/>
              },
              {
                path:"reports/dailyLedger",
                element:<DailyLedger/>
              },
              {
                path:"reports/policyCollection",
                element:<PolicyCollection/>
              },
              {
                path:"reports/ddc",
                element:<DdcReports/>
              },
              {
                path:"reports/suspendedPolicies",
                element:<SuspensionPolicy />
              },
              {
                path:"testimonial/edit/:id",
                element:<Testimonial/>
              },
              {
                path:"/settings/testimonial/list",
                element:<TestimonialList/>
              },
              // {
              //   path:"/settings/paymentConfig",
              //   element:<PaymentConfig />,
              // },
              {
                path:"/settings/emailConfig",
                element:<EmailConfig />,
              },
              {
                path:"/settings/smsConfig",
                element:<SmsConfig />,
              },
              {
                path:"/settings/location",
                element:<Location />,
              },
              {
                path:"/settings/location/create",
                element:<LocationCreate />,
              },
              {
                path:"/settings/location/edit/:id",
                element:<LocationCreate />,
              },
              {
                path:"/settings/location/view/:id",
                element:<LocationView />,
              },
              {
                path: "/employees",
                element: <Employees />,
              },
              {
                path: "/employee/create",
                element: <EmployeeCreate />,
              },
              {
                path: "/employee/edit/:id",
                element: <EmployeeCreate />,
              },
              {
                path: "/employee/view/:id",
                element: <EmployeeCreate />,
              },
              
              {
                path: "/reconciliation",
                element: <Reconciliation />,
              },
              {
                path: "/reports",
                element: <Reports />,
              },
              {
                path: "settings/reminder",
                element: <Remainder />
              },
              {
                path: "settings/reminder/create",
                element: <ReminderCreate />
              },
              {
                path: "settings/reminder/edit/:id",
                element: <ReminderCreate />,
              },
              {
                path: "settings/reminder/view/:id",
                element: <ReminderCreate />,
              },
            ],
          },
        ],
      },
      {
        path: "/",
        element: <ProtectedUserRoute roles={[Roles.TenantAdmin, Roles.TenantEmployee]} />,
        children: [
          {
            path: "",
            element: <Layout />,
            children: [
              {
                path: "/dashboard",
                element: <Dashboard />,
              },
              {
                path: "/profile",
                element: <Profile />,
              },
              
              {
                path: "/customers",
                element: <ClientList />,
              },
              {
                path: "/customers/create",
                element: <CreateClient />,
              },
              {
                path: "/customers/edit/:id",
                element: <CreateClient />,
              },
              {
                path: "/customers/view/:id",
                element: <ClientView />,
              },
              {
                path: "/customers/policy/create/:id",
                element: <CreatePolicy />,
              },
              {
                path: "/customers/policy/edit/:id",
                element: <CreatePolicy />,
              },
              {
                path:"customers/policy/cancel/:id",
                element:<PolicyCancellationForm/>
              },
              {
                path: "/chequelist",
                element: <Chequelist />,
              },
              {
                path: "/cheque/edit/:id",
                element: <ChequeEdit />,
              },
              {
                path: "/cheque/view/:id",
                element: <ChequeView />,
              },
              {
                path: `/customer/policy/cancel/document-sign`,
                element: <DocuSign />,
              },
              {
                path: `/endorsements`,
                element: <EndorsementNotification />,
              },
            ],
          },
        ],
      },
      {
        path: "/",
        element: <ProtectedUserRoute roles={[Roles.Customer]} />,
        children: [
          {
            path: "",
            element: <Layout />,
            children: [
              {
                path:"/customer/dashboard",
                element:<CustomerDashboard/>
              },
              {
                path: "/customerprofile",
                element: <ClientView />,
              },
              {
                path: "/payment-success",
                element: <Success />,
              },
              {
                path: "/payment-decline",
                element: <Decline />,
              },
              {
                path: "/payment-cancel",
                element: <Decline />,
              },
              {
                path: `/customer/policy/document-sign`,
                element: <DocuSign />,
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
      {
        path: "/payment/failed",
        element: <Paymentfailed />,
      },
      {
        path: "/payment/success",
        element: <Paymentsuccess />,
      },
      {
        path: "404",
        element: <PageNotFound />,
      },
      ],
    }
  ],
  {
    basename: "/",
  }
);


export default RoutePath;





