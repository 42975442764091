import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ICustomer {
  customerType: any;
  customer: any;
  employee: any;
  bank: any;
  documents: any;
  contact: any;
}

const initialState: ICustomer = {
  customerType: null,
  customer: {},
  bank: {},
  documents: {},
  employee: {},
  contact: {}
};

export const customerSlice = createSlice({
  name: "action",
  initialState,
  reducers: {
    setCustomType(state, action: PayloadAction<any>) {
      state.customerType = action.payload;
    },
    setCustomer(state, action: PayloadAction<any>) {
      state.customer = action.payload;
    },
    setContact(state, action: PayloadAction<any>) {
      state.contact = action.payload;
    },
    setBank(state, action: PayloadAction<any>) {
      state.bank = action.payload;
    },
    setDocument(state, action: PayloadAction<any>) {
      state.documents = action.payload;
    },
    setEmployee(state, action: PayloadAction<any>) {
      state.employee = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    }),
});

export const { setCustomType, setBank, setCustomer, setDocument, setEmployee, setContact } =
  customerSlice.actions;
export default customerSlice.reducer;
