import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AutoCompleteField,
  SearchInputFiled,
  SelectField,
} from "forms/hoc/formfield";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import React from "react";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/store";
import DownloadIcon from '@mui/icons-material/Download';



const MainContainerWrapper = ({
  children,
  ...props
}: React.PropsWithChildren<MainContainerWrapperType>) => {
  const {
    handleClick,
    handleSecondClick,
    handleTemplateClick,
    search,
    handleSearch,
    headingText,
    buttonText,
    templateButtonText,
    istemplateLink,
    secondButtonText,
    buttonIcon,
    isSearch = false,
    isCreateButton = false,
    isSecondButton = false,
    bgColor,
    searchPlaceHolder,
    buttonBgColor,
    handleSearchClear,
    selectOptions,
    isDriverMap,
    filterPlaceHolder="",
    filterPlaceHolder1="",
    isSelect,
    select_name,
    filter,
    filter1,
    setUpdateValue,
    setUpdateValue1,
    filterList,
    filterList1,
    isFilter,
    isFilter1,
    handleBack,
    px= { md: 2, xs: 0 },
    isBackButton = false ,
    isCustomerTemplate = false,
    isDriverTemplate = false,
    isCustomerMultiTemplate = false
  } = props;
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const settings =  useAppSelector((state)=> state.settings.settings)
  const navigate = useNavigate();

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: { xs: 1, md: 2, lg: 1 },
        px: px,
        // background: "white",
        // mt: 1,
        // mb: 2
      }}
    >
      {headingText && (
        <Stack
          direction={matchDownMD ? "column" : "row"}
          alignItems="center"
          sx={{
            justifyContent: { md: "space-between", xs: "center" },
            // rowGap: { xs: ".5rem" },
            minHeight: { xs: "3rem", md: "2rem", lg: "2rem" },
            height: { lg: "50px", xs: "90px", md: "90px" },
          }}
        >
          {headingText && (
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {/* <Box> */}
              <Box 
                display="flex" 
                alignItems="center"
              >
                {isBackButton && <Box
                  onClick={() => handleBack?handleBack() : navigate(-1)} 
                  sx={{ cursor: "pointer", display: "flex", alignItems: "center", mr: 1 }}
                >
                  <KeyboardBackspaceIcon /> 
                </Box>}
              {/* </Box> */}
                <Typography variant="h2">{headingText}</Typography>
              </Box>
              {isSelect && (
                <Box sx={{ width: "250px", pl: 2 }}>
                  <SelectField
                    values={search}
                    handleChange={
                      handleSearch ? handleSearch : (e) => console.log("e", e)
                    }
                    placeholder={searchPlaceHolder || ""}
                    errors={null}
                    name={select_name || ""}
                    options={selectOptions || []}
                    id={select_name}
                  />
                </Box>
              )}
            </Stack>
          )}
          <Box>
            <Stack
              direction={matchDownMD ? "column-reverse" : "row"}
              spacing={matchDownMD ? 1 : 3}
              sx={
                {
                  rowGap: { xs: ".2rem" },
                }
              }
            >
               {
                isCustomerMultiTemplate&& (
                  <Button
                    className="downloadTemplateBtn"
                    variant="contained"
                    onClick={handleTemplateClick}
                    sx={{
                      height: "3.2rem",
                      padding: "1.5rem",
                      backgroundColor: "#D3D3D3",
                    "&:hover": {
                      backgroundColor: "#D3D3D3"
                      
                    },
                     color:"black"
                    }}
                    startIcon={<DownloadIcon />} // Assuming you imported DownloadIcon from MUI icons
                  >
                    {templateButtonText}
                  </Button>
                )
              }

{isSecondButton && (
                <Button
                  className="createBtn"
                  variant="contained"
                  onClick={handleSecondClick}
                  disableRipple
                  sx={{
                    height: "3.2rem",
                    padding: "1.5rem",
                    backgroundColor: "#D3D3D3",
                    "&:hover": {
                      backgroundColor: "#D3D3D3"
                      
                    },
                    color: {
                      xs: "black",
                      md: "black",
                      lg: "black",
                    },
                    
                  }}
                  startIcon={buttonIcon}
                >
                  {secondButtonText}
                </Button>
              )}
             
      {isCreateButton && (
                <Button
                  className="createBtn"
                  variant="contained"
                  onClick={handleClick}
                  disableRipple
                  sx={{
                    height: "3.2rem",
                    padding: "1.5rem",
                    // backgroundColor: theme.palette.primary?.createButtonColor,
                    backgroundColor :`${settings?.button_background_color ? settings?.button_background_color : theme.palette.primary?.createButtonColor}`,
                    "&:hover": {
                      // backgroundColor: theme.palette.primary?.createButtonColor,
                      backgroundColor :`${settings?.button_background_color ? settings?.button_background_color : theme.palette.primary?.createButtonColor}`,
                    },
                    color: {
                      xs: "white",
                      md: "white",
                      lg: "white",
                    },
                    
                  }}
                  startIcon={buttonIcon}
                >
                  {buttonText}
                </Button>
              )}
              
              {isCustomerTemplate && (
                <Button
                  className="downloadTemplateBtn"
                  variant="contained"
                  // href={`/templates/Individual.xlsx`}
                  href={istemplateLink}
                  sx={{
                    height: "3.2rem",
                    padding: "1.5rem",
                   
                  }}
                  startIcon={<DownloadIcon />} // Assuming you imported DownloadIcon from MUI icons
                >
                  {templateButtonText}
                </Button>
              )}

              {
                isDriverTemplate && (
                  <Button
                    className="downloadTemplateBtn"
                    variant="contained"
                    href={`/template_diver - Copy.xlsx`} 
                    disableRipple
                    sx={{
                      height: "3.1rem",
                      padding: "0 1rem",
                    }}
                    startIcon={<DownloadIcon />} 
                  >
                    Download Template
                  </Button>
                )
              }

              {isDriverMap && (
                <Button
                  className="createBtn"
                  variant="contained"
                  onClick={handleClick}
                  disableRipple
                  sx={{
                    height: "3.1rem",
                    padding: "0 1rem",
                    backgroundColor:
                      buttonBgColor || theme.palette.primary?.createButtonColor,
                    "&:hover": {
                      backgroundColor:
                        buttonBgColor ||
                        theme.palette.primary?.createButtonColor,
                    },
                  }}
                  // startIcon={buttonIcon}
                >
                  Select Driver<span style={{ fontSize: ".65rem" }}>(s)</span>
                </Button>
              )}
              {isSearch && (
                <SearchInputFiled
                  values={search}
                  handleChange={handleSearch}
                  startIcon={<SearchIcon />}
                  endIcon={<Close />}
                  placeholder={searchPlaceHolder ? searchPlaceHolder : "Search"}
                  handleSearchClear={handleSearchClear}
                  // errors={
                  //   search && search.length < 3
                  //     ? "Please enter at least 3 characters"
                  //     : null
                  // }
                />
              )}
              {isFilter && (
                
                <Box 
                sx={{
                  width:{
                   sm:"10rem",
                   md:"20rem",
                   lg:"20rem",                   
                  }
                }}
                >
                  <AutoCompleteField
                    placeholder={filterPlaceHolder}
                    isRequired={false}
                    values={filter || null}
                    name="type"
                    handleChange={(type: any) => setUpdateValue(type)}
                    options={filterList}
                    id="type"
                  />
                
                </Box>
              )}
              {isFilter1 && (
                
                <Box 
                sx={{
                  width:{
                   sm:"10rem",
                   md:"20rem",
                   lg:"20rem",                   
                  }
                }}
                >
                  <AutoCompleteField
                    placeholder={filterPlaceHolder1}
                    isRequired={false}
                    values={filter1 || null}
                    name="type"
                    handleChange={(type: any) => setUpdateValue1(type)}
                    options={filterList1}
                    id="type"
                  />
                
                </Box>
              )}
            </Stack>
          </Box>
        </Stack>
      )}
      <Box
        mt={2}
        // mb={4}
        sx={{
          width: "100%",
          minHeight: "78vh",
          // display: "flex",
          backgroundColor: bgColor ?? theme.palette.background.paper,
          // boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

type MainContainerWrapperType = {
  handleClick?: (_: any) => void;
  handleSecondClick?: (_: any) => void;
  handleTemplateClick?: (_: any) => void;
  search?: string | null;
  handleSearch?: (_: any) => void;
  headingText?: string;
  secondButtonText?: string;
  isSearch?: boolean;
  isCreateButton?: boolean;
  isSecondButton?: boolean;
  buttonText?: string;
  templateButtonText?:string;
  buttonIcon?: any;
  bgColor?: string;
  searchPlaceHolder?: string;
  buttonBgColor?: string;
  handleSearchClear?: (_: any) => void;
  isDriverMap?: boolean;
  buttonMapText?: string;
  isSelect?: boolean;
  selectOptions?: any[];
  select_name?: string;
  filter?: any;
  filter1?: any;
  filterList?: any;
  filterList1?: any;
  isFilter?: boolean;
  isFilter1?: boolean;
  setUpdateValue?: any;
  setUpdateValue1?: any;
  px?: any;
  filterPlaceHolder?: string;
  filterPlaceHolder1?: string;
  isCustomerTemplate?: boolean;
  isDriverTemplate?:boolean;
  isCustomerMultiTemplate?: boolean;
  istemplateLink?: string;
  isBackButton?:boolean;
  handleBack?: () => void;
};

export default MainContainerWrapper;
